import React from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import PageHeader from '../../components/pageHeader'
import * as PageStyle from '../../components/css/pages/pageVaraUtbildningar.module.css'
const VaraUtbildningar = (props) => (
  <Layout>
    <Seo title='Trafikskolans utbildningar' />
    <PageHeader text='Våra utbildningar' />
    <div className='container'>
      <div className='row'>

        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12pb-2'>
          <h2>Praktiskutbildning</h2>
          <p>
            Hos oss på Danderyds trafikskola får du en körkortsutbildning baserad på över 50 års erfarenhet i branschen.
            Vi är i särklass den mest eftertraktade och etablerade trafikskolan i Danderyd med omnejd. <br />
            Trafikskolan har i dag sitt fokus på personbilsutbildning (B-behörighet).
            Vi har både manuella och automatväxlade bilar vilket gör att vi även kan erbjuda B-behörighet med villkor automat.
          </p>
        </div>

        <div className='col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 pb-2'>
          <Link to='/utbildningar/personbilsutbildning'>
            <div className='card border-0'>
              {/* <img className='card-img-top' src={require('../../images/utbildningar/personbilsutbildning.jpg')} alt='personbilsutbildning' /> */}
              <Img fluid={props.data.imageOne.childImageSharp.fluid} alt='personbilsutbildning' className='rounded' />
              <div className='card-body text-center pt-0'>
                <h5 className={`card-title  ${PageStyle.cardTitle}`}>Läs mera</h5>
                <h5 className='card-title mt-5'>Personbilsutbildning</h5>
              </div>
            </div>
          </Link>
        </div>

        <div className='col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 pb-2'>
          <Link to='/utbildningar/individuell-Intensivutbildning'>
            <div className='card border-0'>
              {/* <img className='card-img-top' src={require('../../images/utbildningar/individuell_intensivutbildning.jpg')} alt='individuell intensivutbildning (B)' /> */}
              <Img fluid={props.data.imageTwo.childImageSharp.fluid} alt='individuell intensivutbildning (B)' className='rounded' />
              <div className='card-body text-center pt-0'>
                <h5 className={`card-title  ${PageStyle.cardTitle}`}>Läs mera</h5>
                <h5 className='card-title mt-5'>Individuell intensivutbildning (B)</h5>
              </div>
            </div>
          </Link>
        </div>

        <div className='col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3  pb-2'>
          <Link to='/utbildningar/intensivkurs-tva-veckor'>
            <div className='card border-0'>
              {/* <img className='card-img-top' src={require('../../images/utbildningar/intensivutbildning_2_veckor.jpg')} alt='intensivutbildning i 2 veckor (B)' /> */}
              <Img fluid={props.data.imageThree.childImageSharp.fluid} alt='intensivutbildning i 2 veckor (B)' className='rounded' />
              <div className='card-body text-center pt-0'>
                <h5 className={`card-title  ${PageStyle.cardTitle}`}>Läs mera</h5>
                <h5 className='card-title mt-5'>Intensivutbildning i 2 veckor (B)</h5>
              </div>
            </div>
          </Link>
        </div>

        <div className='col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3  pb-2'>
          <Link to='/utbildningar/riskutbildningar'>
            <div className='card border-0'>
              {/* <img className='card-img-top' src={require('../../images/utbildningar/riskutbildning2.jpg')} alt='riskutbildning del 2' /> */}
              <Img fluid={props.data.imageFour.childImageSharp.fluid} alt='riskutbildning2.jpg' className='rounded' />
              <div className='card-body text-center pt-0'>
                <h5 className={`card-title  ${PageStyle.cardTitle}`}>Läs mera</h5>
                <h5 className='card-title mt-5'>Riskutbildning del 2</h5>
              </div>
            </div>
          </Link>
        </div>

        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pb-2'>
          <h2>Kurser</h2>
          <p>
            Förutom att vi erbjuder körlektioner för B-behörighet har vi även introduktionsutbildningar (Handledarutbildning)
            för privat övningskörning hemma och riskutbildning del 1 i våra lokaler. Vi hjälper dig också att boka riskutbildning del 2.
            Vi erbjuder även teorikvällar med fokus på körkortsteori.
          </p>
        </div>

      </div>
    </div>

    <div className='container'>
      <div className='row justify-content-between'>

        <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 pb-2'>
          <Link to='/utbildningar/handledarutbildning'>
            <div className='card border-0'>
              {/* <img className='card-img-top' src={require('../../images/utbildningar/handledarutbildning.jpg')} alt='handledarutbildning' /> */}
              <Img fluid={props.data.imageFive.childImageSharp.fluid} alt='handledarutbildning' className='rounded' />
              <div className='card-body text-center pt-0'>
                <h5 className={`card-title  ${PageStyle.cardTitle}`}>Läs mera</h5>
                <h5 className='card-title mt-5'>Handledarutbildning</h5>
              </div>
            </div>
          </Link>
        </div>

        <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 pb-2'>
          <Link to='/utbildningar/teoriundervisning'>
            <div className='card border-0'>
              {/* <img className='card-img-top' src={require('../../images/utbildningar/teoriundervisning.jpg')} alt='teoriundervising' /> */}
              <Img fluid={props.data.imageSix.childImageSharp.fluid} alt='teoriundervisning' className='rounded' />
              <div className='card-body text-center pt-0'>
                <h5 className={`card-title  ${PageStyle.cardTitle}`}>Läs mera</h5>
                <h5 className='card-title mt-5'>Teoriundervising</h5>
              </div>
            </div>
          </Link>
        </div>

        <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 pb-5'>
          <Link to='/utbildningar/riskutbildningar'>
            <div className='card border-0'>
              {/* <img className='card-img-top' src={require('../../images/utbildningar/riskutbildning1.jpg')} alt='riskutbildning del 1' /> */}
              <Img fluid={props.data.imageSeven.childImageSharp.fluid} alt='riskutbildning del 1' className='rounded' />
              <div className='card-body text-center pt-0'>
                <h5 className={`card-title  ${PageStyle.cardTitle}`}>Läs mera</h5>
                <h5 className='card-title mt-5'>Riskutbildning del 1</h5>
              </div>
            </div>
          </Link>
        </div>

      </div>
    </div>

  </Layout>
)

export default VaraUtbildningar

export const pageQuery = graphql`
  query {
    imageOne: file(relativePath: { eq: "utbildningar/personbilsutbildning.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 10000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    imageTwo: file(relativePath: { eq: "utbildningar/individuell_intensivutbildning.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  
    imageThree: file(relativePath: { eq: "utbildningar/intensivutbildning_2_veckor.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  
    imageFour: file(relativePath: { eq: "utbildningar/riskutbildning2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  
    imageFive: file(relativePath: { eq: "utbildningar/handledarutbildning.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  
    imageSix: file(relativePath: { eq: "utbildningar/teoriundervisning.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  
    imageSeven: file(relativePath: { eq: "utbildningar/riskutbildning1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
} 
`
